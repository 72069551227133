<template>
  <section>
    <b-row class="match-height">
      <b-col
        sm="12"
        md="12"
        lg="8"
        xl="8"
      >
        <div style="position: relative; padding-top: 56.25%;">
          <iframe
            :src="link_watch"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
            style="position:absolute;top:0;left:0;width:100%;height:100%;"
          />
        </div>
        <br>
        <b-tabs pills>
          <b-tab
            title="Visão geral"
            active
          >
            <b-card>
              <h3>Sobre este curso</h3>
              <p>{{ curso.description }}</p>
              <hr size="50">
              <h2>Detalhes do curso</h2>
              <b-card-text>
                Nível de experiência: {{ curso.level }}
              </b-card-text>
              <b-card-text>
                Idiomas:
                {{ curso.language }},
              </b-card-text>
              <b-card-text>
                Legendas:
                {{ curso.caption }}
              </b-card-text>
              <b-card-text>
                Aulas: 0000
              </b-card-text>
              <hr size="50">
              <h2>Descrição: </h2>
              <p>{{ curso.about }}</p>
            </b-card>
          </b-tab>
          <b-tab title="Professores">
            <b-card>
              integrating...
              <!-- <div v-if="speakers.length > 0">
                <CarouselInterval :speakers="speakers" />
              </div> -->
            </b-card>
          </b-tab>
          <b-tab title="Perguntas e respostas">
            <b-card>
              <b-media
                v-for="question in questionsApi"
                :key="question.id"
              >
                <template #aside>
                  <b-avatar
                    size="lg"
                    :src="question.user.photo"
                  />
                </template>

                <h5 class="mt-0">
                  {{ question.user.name }}
                </h5>
                <b-card-text>
                  {{ question.time }} - {{ question.question }}
                </b-card-text>
                <b-media
                  v-for="answer in question.answers"
                  :key="answer.id"
                  class="mt-3"
                >
                  <template #aside>
                    <b-avatar
                      size="lg"
                      :src="answer.user.photo"
                    />
                  </template>

                  <h5 class="mt-0">
                    {{ answer.user.name }}
                  </h5>
                  <b-card-text class="mb-0">
                    {{ answer.text }}
                  </b-card-text>
                </b-media>
              </b-media>
              <br>
              <p>Faça uma nova pergunta</p>
              <b-form-textarea
                id="textarea-auto-height"
                v-model="question_text"
                placeholder="Digite sua pergunta ..."
                rows="3"
                max-rows="8"
                class="mb-1 mb-xl-0"
              />
              <b-button
                v-if="question_text != ''"
                variant="primary"
                style="margin: 5px"
                @click="toSendQuestion(question_text)"
              >
                <span class="text-nowrap">Fazer pergunta</span>
              </b-button>
              <b-button
                v-else
                variant="primary"
                style="margin: 5px"
                disabled
              >
                <span class="text-nowrap">Fazer pergunta</span>
              </b-button>
            </b-card>
          </b-tab>
          <b-tab
            title="Anotações"
          >
            <b-card>
              <b-card-text
                v-for="annotation in notesApi"
                :key="annotation.id"
              >
                - {{ annotation.text }}
              </b-card-text>
              <b-form-textarea
                id="textarea-auto-height"
                v-model="annotation_text"
                placeholder="Faça sua anotação ..."
                rows="3"
                max-rows="8"
                class="mb-1 mb-xl-0"
              />
              <b-button
                v-if="annotation_text != ''"
                variant="primary"
                style="margin: 5px"
                @click="toSendAnnotation(annotation_text)"
              >
                <span class="text-nowrap">Fazer anotação</span>
              </b-button>
              <b-button
                v-else
                variant="primary"
                style="margin: 5px"
                disabled
              >
                <span class="text-nowrap">Fazer anotação</span>
              </b-button>
            </b-card>
          </b-tab>
          <b-tab
            title="Baixar material"
            disabled
          >
            <b-card>
              <b-button
                variant="primary"
                target="_blank"
                :href="'sdsd'"
              >
                Material
              </b-button>
            </b-card>
          </b-tab>
        </b-tabs>
      </b-col>
      <b-col
        sm="12"
        md="12"
        lg="4"
        xl="4"
      >
        <app-collapse accordion>
          <app-collapse-item
            v-for="module in curso.modules"
            :key="module.id"
            style="font-size: 18px !important"
            :title="module.title"
          >
            <b-card>
              <b-row>
                <b-col
                  v-for="lesson in module.lessons"
                  :key="lesson.id"
                  style="padding: 5px"
                >
                  <b-row class="match-height">
                    <b-col
                      sm="5"
                      md="5"
                      lg="5"
                      xl="5"
                    >
                      <img
                        sizes="(max-width: 640px) 100vw, 640px"
                        :src="`https://i.vimeocdn.com/video/${lesson.thumb}?mw=1500&mh=844`"
                      >
                    </b-col>
                    <b-col
                      style="padding: 0px !important"
                      sm="7"
                      md="7"
                      lg="7"
                      xl="7"
                    >
                      <h4>{{ lesson.title }}</h4>
                      <h5>{{ lesson.description }}</h5>
                    </b-col>
                  </b-row>
                  <b-button
                    variant="primary"
                    style="margin: 5px"
                    @click="watch(lesson.link, lesson.id)"
                  >
                    <span class="text-nowrap">Assistir</span>
                  </b-button>
                  <!--eslint-disable-next-line vue/valid-v-for -->
                  <b-button
                    v-for="additional in lesson.additionals"
                    style="margin: 5px;"
                    variant="primary"
                    target="_blank"
                    :href="`${baseStorage}/storage/additional/pdf/lesson-${lesson.id}/${additional.link}`"
                  >
                    Material - {{ additional.title }}
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </app-collapse-item>
        </app-collapse>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import CarouselInterval from '@/components/carousel/CarouselInterval.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BFormTextarea } from 'bootstrap-vue'
import CoursesServices from './coursesServices'
import Vimeo from './Vimeo.vue'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    // CarouselInterval,
    BFormTextarea,
    // eslint-disable-next-line vue/no-unused-components
    Vimeo,
  },
  data() {
    return {
      baseStorage: process.env.VUE_APP_API_BASE,
      link_watch: null,
      lesson_id: null,
      questionsApi: [],
      notesApi: [],
      annotation_text: '',
      question_text: '',
      form: {
        time: '',
        text: ''
      },
      annotations: [
        {
          id: 1,
          text: 'Essa foi uma anotação feita para teste',
          time: '12:30'
        },
        {
          id: 2,
          text: 'Essa foi outra anotação feita para teste',
          time: '15:30'
        }
      ],
      questions: [
        {
          id: 1,
          user: {
            id: 1,
            name: 'Eduardo Breno',
            photo: `${process.env.VUE_APP_API_BASE}/storage/image/1637879374.png`
          },
          time: '12:11',
          text: 'Como fazer determinada coisa?',
          answers: [
            {
              id: 1,
              user: {
                id: 3,
                name: 'Jose de carvalho',
                photo: `${process.env.VUE_APP_API_BASE}/storage/image/1637879364.png`
              },
              text: 'Fazendo exatamente como consta no manual X Y Z, levando em consideração o risco X Y Z.'
            }
          ]
        }
      ],
      speakers: [
        {
          photo: null,
          name_badge: 'Teste',
          mini_curriculo: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent efficitur urna metus, eu convallis enim sollicitudin quis. Sed semper faucibus suscipit. Morbi eleifend bibendum porttitor. Pellentesque vulputate neque sit amet libero cursus, ac molestie tortor lobortis. Nunc molestie felis interdum neque finibus, vel sagittis enim tempus. In porta feugiat metus id mollis.',
          instagram: 'idjfdif'
        }
      ],
      course: {
        about: {
          short_description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent efficitur urna metus, eu convallis enim sollicitudin quis. Sed semper faucibus suscipit. Morbi eleifend bibendum porttitor. Pellentesque vulputate neque sit amet libero cursus, ac molestie tortor lobortis. Nunc molestie felis interdum neque finibus, vel sagittis enim tempus. In porta feugiat metus id mollis',
          experience_level: 'Todos os niveis',
          quantity_of_class: '515',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent efficitur urna metus, eu convallis enim sollicitudin quis. Sed semper faucibus suscipit. Morbi eleifend bibendum porttitor. Pellentesque vulputate neque sit amet libero cursus, ac molestie tortor lobortis. Nunc molestie felis interdum neque finibus, vel sagittis enim tempus. In porta feugiat metus id mollis Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent efficitur urna metus, eu convallis enim sollicitudin quis. Sed semper faucibus suscipit. Morbi eleifend bibendum porttitor. Pellentesque vulputate neque sit amet libero cursus, ac molestie tortor lobortis. Nunc molestie felis interdum neque finibus, vel sagittis enim tempus. In porta feugiat metus id mollis Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent efficitur urna metus, eu convallis enim sollicitudin quis. Sed semper faucibus suscipit. Morbi eleifend bibendum porttitor. Pellentesque vulputate neque sit amet libero cursus, ac molestie tortor lobortis. Nunc molestie felis interdum neque finibus, vel sagittis enim tempus. In porta feugiat metus id mollis Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent efficitur urna metus, eu convallis enim sollicitudin quis. Sed semper faucibus suscipit. Morbi eleifend bibendum porttitor. Pellentesque vulputate neque sit amet libero cursus, ac molestie tortor lobortis. Nunc molestie felis interdum neque finibus, vel sagittis enim tempus. In porta feugiat metus id mollis',
          languages: [
            {
              id: 0,
              title: 'Português'
            }
          ],
          subtitles: [
            {
              id: 0,
              title: 'Português'
            }
          ]
        },
      },
      curso: {},
      // adicionar mais dados dentro de data
      data: {
        id: '',
        width: 640,
        lesson_id: ''
      }
    }
  },
  computed: {},
  watch: {},
  mounted() {
    CoursesServices().getCourse({ course_slug: this.$route.params.course_slug }).then(api => {
      console.log(api)
      this.curso = api.data.response.course
      this.watch(this.curso.modules[0].lessons[0].link, this.curso.modules[0].lessons[0].id)
    })
  },
  methods: {
    makeToast(variant = null, text_title, text) {
      this.$bvToast.toast(text, {
        title: text_title,
        variant,
        solid: true,
      })
    },
    watch(link, id) {
      this.link_watch = `https://player.vimeo.com/video/${link}?h=d3d8e3999f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`
      this.lesson_id = id
      this.data.id = link
      this.data.lesson_id = id
      CoursesServices().getQuestions({ lesson_id: this.lesson_id }).then(api => {
        console.log(api)
        this.questionsApi = api.data.response.questions
      })
      CoursesServices().getNotes({ lesson_id: this.lesson_id }).then(api => {
        console.log(api)
        this.notesApi = api.data.response.notes
      })
    },
    toSendQuestion(question_text) {
      try {
        this.form.question = question_text
        this.form.lesson_id = this.lesson_id
        console.log('Enviando form', this.form.text)
        this.makeToast('success', 'Pergunta enviada com sucesso', 'Em breve nosso instrutor entrarar em contato!')
      } catch {
        console.log('erro ao enviar')
        this.makeToast('success', 'Houver um problema ao enviar a pegunta', 'Pedimos que tente novamente!')
      } finally {
        this.form.text = ''
        this.form.time = ''
        this.question_text = ''
      }
      CoursesServices().postQuestion(this.form).then(api => {
        if (api.data.status === 400) {
          this.$toast({
            component: ToastificationContent,
            props: {
              icon: 'InfoIcon',
              title: api.data.message,
              variant: 'danger',
            }
          })
        } else if (api.data.status === 200) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: api.data.message,
              icon: 'InfoIcon',
              variant: 'success',
            }
          })
          CoursesServices().getQuestions({ lesson_id: this.lesson_id }).then(tapi => {
            console.log(api)
            this.questionsApi = tapi.data.response.questions
          })
        }
      }).catch(err => {
        this.$toast({
          component: ToastificationContent,
          props: {
            icon: 'InfoIcon',
            title: err.message,
            variant: 'danger',
          }
        })
      })
    },
    toSendAnnotation(annotation_text) {
      this.form.text = annotation_text
      this.form.lesson_id = this.lesson_id
      CoursesServices().postNotes(this.form).then(api => {
        if (api.data.status === 400) {
          this.$toast({
            component: ToastificationContent,
            props: {
              icon: 'InfoIcon',
              title: api.data.message,
              variant: 'danger',
            }
          })
        } else if (api.data.status === 200) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: api.data.message,
              icon: 'InfoIcon',
              variant: 'success',
            }
          })
          CoursesServices().getNotes({ lesson_id: this.lesson_id }).then(rapi => {
            console.log(rapi)
            this.notesApi = rapi.data.response.notes
            this.annotation_text = ''
          })
        }
      }).catch(err => {
        this.$toast({
          component: ToastificationContent,
          props: {
            icon: 'InfoIcon',
            title: err.message,
            variant: 'danger',
          }
        })
      })
    }
  },
}
</script>
<style scoped>
.collapse-title{
  font-size: 18px !important;
}
</style>
